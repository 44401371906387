import React from "react";
import StepsSection from "@common/CommonSections/StepsSection/StepsSection";
import { LayoutWrapper } from "../../../../styles/CommonStyles";

const steps = [
    {
        title: "Discovery and planning",
        description: `
                Our offshore software product development company starts every single project with thorough planning.
                Together with the stakeholders, we define the scope, set priorities and create a detailed development
                roadmap and product plan!
            `,
    },
    {
        title: "Design",
        description:
            "Our designers will create a clean and intuitive UX with beautiful UI, focusing on the elements " +
            "that will bring the most value to your users and make their experience seamless.",
    },
    {
        title: "Development and testing",
        description:
            "Using the Agile methodology, our team of skilled engineers and product managers will develop " +
            "your software product with industry-leading technologies and quality assurance best practices.",
    },
    {
        title: "Deployment and maintenance",
        description:
            "Once the final product is ready, our software product development company will take care of its deployment and bring it to the first users. " +
            "We ensure that it runs smoothly and is scalable for continued updates and ongoing maintenance.",
    },
];

const StepsSectionWrapper = () => (
    <LayoutWrapper>
        <StepsSection steps={steps} />
    </LayoutWrapper>
);

export default StepsSectionWrapper;
