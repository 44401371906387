import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const StepsItemWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14.25rem;

    &:nth-child(even) {
        justify-content: flex-end;
    }

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-bottom: 6.25rem;

        &:nth-child(even) {
            justify-content: flex-start;
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: calc(100% - 1.5rem);
        margin-bottom: 3.5rem;
    }
`;

export const StepsItemContainer = styled.div`
    width: 33.56rem;
    min-height: 8.5rem;
    opacity: ${props => (!props.isActive ? 0.2 : null)};
    transition: 0.5s;
    z-index: 10;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100%;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: auto;
    }
`;

export const Title = styled.h3`
    margin: 0 0 1.5rem 0;
    font-size: 1.75rem;
    line-height: 2.5rem;
    color: rgba(255, 255, 255, 0.8);

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
    }
`;
