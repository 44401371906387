import React from "react";
import StepsSection from "@common/CommonSections/StepsSection/StepsSection";
import { LayoutWrapper } from "../../../../styles/CommonStyles";

const steps = [
    {
        title: "Introduction",
        description:
            "Everything starts with an introductory meeting where we will get " +
            "to know each other, clarify your needs, and answer all your questions.",
    },
    {
        title: "Team set-up",
        description:
            "If you hire dedicated development team with us, we select top talents from our pool based on your " +
            "requirements and share their profiles for you to review.",
    },
    {
        title: "Interviews",
        description:
            "We encourage our clients to meet with the candidates to evaluate their " +
            "skills and choose the best match for their needs.",
    },
    {
        title: "Kick-off",
        description:
            "We promise that the onboarding of our dedicated software development teams will be hassle-free, " +
            "and they will deliver excellent results quickly.",
    },
];

const StepsSectionWrapper = () => (
    <LayoutWrapper>
        <StepsSection steps={steps} />
    </LayoutWrapper>
);

export default StepsSectionWrapper;
