import React, { useEffect, useMemo, useRef, useState } from "react";
import stepsBackground from "@images/product-development/stepsBackground.svg";
import stepsBackgroundTablet from "@images/product-development/stepsBackground-tablet.svg";
import stepsBackgroundMobile from "@images/product-development/stepsBackground-mobile.svg";
import animatedBlock from "@images/product-development/animatedBlock.svg";
import useComponentsGenerator from "@hooks/useComponentsGenerator";
import useWindowDimensions from "@hooks/useWindowDimensions";
import PageHeading from "@common/PageHeading/PageHeading";
import { ReactSVG } from "react-svg";
import RemConverter from "@utils/remConverter";
import StepsItem from "./StepsItem/StepsItem";
import {
    StepsSectionContainer,
    BackgroundImageContainer,
    StepsWrapper,
} from "./StepsSection.style";

const ANIMATED_BLOCK_HEIGHT = RemConverter.convertRemToPixels(8.25);
const ANIMATED_BLOCK_HEIGHT_TABLET = 140;
const ANIMATED_BLOCK_HEIGHT_MOBILE = 56;

const BACKGROUND_ITEM_HEIGHT = RemConverter.convertRemToPixels(4.125);
const BACKGROUND_ITEM_HEIGHT_TABLET = 105;
const BACKGROUND_ITEM_HEIGHT_MOBILE = 40;

const KEY_POINTS = [
    RemConverter.convertRemToPixels(22.75),
    RemConverter.convertRemToPixels(45.188),
    RemConverter.convertRemToPixels(68.125),
];
const TOP_OFFSET_COEF = 1.85;
const RIGHT_OFFSET_COEF = 1;
const ANIMATED_BLOCK_OFFSET = 5;
const ITEM_OFFSET_TABLET = 100;

const StepsSection = ({ steps }) => {
    const containerRef = useRef(null);
    const containerHeight = useRef(null);
    const stepItemRef = useRef(null);
    const backgroundImageRef = useRef(null);
    const backgroundContainerRef = useRef(null);
    const animatedElementRef = useRef(null);
    const startedPosition = useRef(null); // useRef is using to keep it updated in callback func
    const keyPointIndex = useRef(0);
    const topOffset = useRef(0);
    const prevBackgroundItem = useRef(0);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    const [backgroundHeight, setBackgroundHeight] = useState(null);
    const [componentElements, generateComponents] =
        useComponentsGenerator(steps);
    const { isDesktop, isMobile } = useWindowDimensions();

    const animationItemHeight = useMemo(() => {
        if (isDesktop) {
            return BACKGROUND_ITEM_HEIGHT;
        }
        if (isMobile) {
            return BACKGROUND_ITEM_HEIGHT_MOBILE;
        }
        return BACKGROUND_ITEM_HEIGHT_TABLET;
    }, [isDesktop, isMobile]);

    const setTransform = (top, right) => {
        animatedElementRef.current.nonReactElement.style.transform = `translate(-${top}px, ${right}px)`;
    };

    const setAnimatedBlockPos = offset => {
        if (!KEY_POINTS[keyPointIndex.current]) {
            if (
                offset * RIGHT_OFFSET_COEF <=
                KEY_POINTS[keyPointIndex.current - 1]
            ) {
                keyPointIndex.current -= 1;
            } else {
                setTransform(
                    RemConverter.convertRemToPixels(41.875),
                    RemConverter.convertRemToPixels(68.125)
                );
                return;
            }
        }

        if (
            topOffset.current < ANIMATED_BLOCK_OFFSET &&
            keyPointIndex.current === 0
        ) {
            setTransform(0, 0);
        }

        setTransform(
            topOffset.current * TOP_OFFSET_COEF,
            offset * RIGHT_OFFSET_COEF
        );

        if (offset * RIGHT_OFFSET_COEF > KEY_POINTS[keyPointIndex.current]) {
            keyPointIndex.current += 1;
        } else if (keyPointIndex.current > 0) {
            if (
                offset * RIGHT_OFFSET_COEF <
                KEY_POINTS[keyPointIndex.current - 1]
            ) {
                keyPointIndex.current -= 1;
            }

            const currentScroll =
                KEY_POINTS[keyPointIndex.current - 1] / RIGHT_OFFSET_COEF;

            if (keyPointIndex.current % 2 !== 0) {
                topOffset.current = currentScroll - (offset - currentScroll);
            } else {
                topOffset.current =
                    offset - currentScroll - ANIMATED_BLOCK_OFFSET;
            }
        } else {
            topOffset.current = offset;
        }
    };

    const setAnimatedBlockPosMobile = offset => {
        let currentOffset;

        if (isMobile) {
            currentOffset =
                ANIMATED_BLOCK_HEIGHT_MOBILE - ANIMATED_BLOCK_HEIGHT_MOBILE / 4;
        } else {
            currentOffset =
                ANIMATED_BLOCK_HEIGHT_TABLET - ANIMATED_BLOCK_HEIGHT_TABLET / 4;
        }

        if (offset >= containerHeight.current - currentOffset) {
            setTransform(0, containerHeight.current - currentOffset);
        } else if (offset <= 0) {
            setTransform(0, 0);
        } else {
            setTransform(0, offset);
        }
    };

    const setActivedBackgroundItems = offset => {
        const backgroundItems =
            backgroundImageRef.current.nonReactElement.children;
        let backgroundItemsIndex = Math.round(
            (offset * (isDesktop ? TOP_OFFSET_COEF : 1.5)) / animationItemHeight
        );

        if (keyPointIndex.current > 1 && isDesktop)
            backgroundItemsIndex += keyPointIndex.current - 1;

        if (backgroundItemsIndex < prevBackgroundItem.current) {
            for (
                let i = backgroundItemsIndex + 1;
                i < backgroundItems.length;
                i += 1
            ) {
                backgroundItems[i]?.classList?.remove("steps-section__active");
            }
        } else {
            for (let i = backgroundItemsIndex; i >= 0; i -= 1) {
                backgroundItems[i]?.classList?.add("steps-section__active");
            }
        }
        prevBackgroundItem.current = backgroundItemsIndex;
    };

    useEffect(() => {
        const sectionObserver = new IntersectionObserver(
            entries => {
                setIsSectionVisible(entries[0].isIntersecting);
            },
            { root: null, threshold: [0], rootMargin: "160px" }
        );

        sectionObserver.observe(containerRef.current);

        generateComponents((stepInfo, index) => {
            componentElements.push(
                <StepsItem
                    title={stepInfo.title}
                    description={stepInfo.description}
                    elementRef={index === 0 ? stepItemRef : null}
                    key={index + stepInfo.title}
                />
            );
        });

        return () => sectionObserver.disconnect();
    }, []);

    useEffect(() => {
        if (isMobile === undefined) return;

        const stepItemHeight =
            stepItemRef.current.getBoundingClientRect().height;
        let ItemOffset;

        if (isMobile) {
            ItemOffset = ANIMATED_BLOCK_HEIGHT_MOBILE;
        } else {
            ItemOffset = ITEM_OFFSET_TABLET;
        }
        // here: remake
        setBackgroundHeight((stepItemHeight + ItemOffset) * 3);
    }, [isMobile]);

    useEffect(() => {
        const onScroll = () => {
            if (startedPosition.current) {
                const offset = window.scrollY - startedPosition.current;

                if (isDesktop) {
                    setAnimatedBlockPos(offset);
                } else {
                    setAnimatedBlockPosMobile(offset);
                }
                setActivedBackgroundItems(offset);
            } else if (
                Math.round(
                    animatedElementRef.current.nonReactElement.getBoundingClientRect()
                        .top
                ) <=
                window.innerHeight / 2 - ANIMATED_BLOCK_HEIGHT / 2
            ) {
                startedPosition.current = window.scrollY;
                containerHeight.current =
                    backgroundContainerRef.current.getBoundingClientRect()
                        .height - ANIMATED_BLOCK_OFFSET;
            }
        };

        if (isSectionVisible) {
            document.addEventListener("scroll", onScroll);
        } else {
            document.removeEventListener("scroll", onScroll);
        }

        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [isSectionVisible]);

    return (
        <StepsSectionContainer ref={containerRef}>
            <PageHeading heading="Process" />
            <StepsWrapper>
                <ReactSVG
                    src={animatedBlock}
                    alt="animated block"
                    className="steps-section__animated-block"
                    ref={animatedElementRef}
                />
                <BackgroundImageContainer
                    animationItemHeight={animationItemHeight}
                    backgroundHeight={backgroundHeight}
                    ref={backgroundContainerRef}
                >
                    {isDesktop && (
                        <ReactSVG
                            ref={backgroundImageRef}
                            src={stepsBackground}
                            alt="background image"
                        />
                    )}
                    {!isDesktop && !isMobile && (
                        <ReactSVG
                            ref={backgroundImageRef}
                            src={stepsBackgroundTablet}
                            alt="background image"
                        />
                    )}
                    {isMobile && (
                        <ReactSVG
                            ref={backgroundImageRef}
                            src={stepsBackgroundMobile}
                            alt="background image"
                        />
                    )}
                </BackgroundImageContainer>
                <div>{componentElements}</div>
            </StepsWrapper>
        </StepsSectionContainer>
    );
};

export default StepsSection;
