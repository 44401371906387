import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { HEADER_HEIGHT } from "@constants";
import { Description } from "../../../../../styles/CommonStyles";
import { StepsItemWrapper, StepsItemContainer, Title } from "./StepsItem.style";

const StepsItem = ({ title, description, elementRef }) => {
    const TextContainerRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const { isDesktop, isMobile } = useWindowDimensions();

    useEffect(() => {
        const MARGIN_DESKTOP = 228;
        const MARGIN_MOBILE = 56;

        let rootMargin;

        if (isDesktop) {
            rootMargin = MARGIN_DESKTOP / 4;
        } else if (isMobile) {
            rootMargin = -MARGIN_MOBILE / 2;
        } else {
            rootMargin = 0;
        }

        const observer = new IntersectionObserver(
            entries => {
                setIsActive(entries[0].isIntersecting);
            },
            {
                root: null,
                threshold: [0],
                rootMargin: `-${
                    (window.innerHeight - HEADER_HEIGHT) / 2 - rootMargin
                }px 0px`,
            }
        );

        observer.observe(TextContainerRef.current);

        return () => observer.disconnect();
    }, [isDesktop]);

    return (
        <StepsItemWrapper ref={elementRef}>
            <StepsItemContainer ref={TextContainerRef} isActive={isActive}>
                <Title>{title}</Title>
                <Description margin="0">{description}</Description>
            </StepsItemContainer>
        </StepsItemWrapper>
    );
};

export default StepsItem;
