import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

// The actual height of the item is less, because the item is rotated
const ITEM_ROTATED_HEIGHT_TABLET = 37;
const ITEM_ROTATED_HEIGHT_MOBILE = 13;

export const StepsSectionContainer = styled.div`
    position: relative;
    padding: 5rem 0;

    & .page-heading__heading {
        margin-bottom: 2rem;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            margin-bottom: 2.5rem;
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            margin-bottom: 1.5rem;
        }
    }

    & path {
        transition: 0.5s;
    }

    & .steps-section {
        &__animated-block {
            position: absolute;
            left: 50%;
            transform: translateX(-25%);
            margin-left: 19rem;
            z-index: 10;

            & svg {
                width: 7.625rem;
                height: 8.25rem;
            }

            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                left: 0;
                margin-left: 0;
                transform: translateX(0);

                & svg {
                    width: 8.125rem;
                    height: 8.75rem;

                    & :nth-child(2) {
                        fill: #593b4b;
                    }

                    & :nth-child(3) {
                        fill: #c4b180;
                    }

                    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                        width: 3.25rem;
                        height: 3.5rem;
                    }
                }
            }
        }

        &__active {
            stroke: #c4b180;
        }
    }
`;

export const BackgroundImageContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4.125rem;

    div {
        z-index: 1;

        & svg {
            width: 49.938rem;
            height: 72.438rem;
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        position: static;
        min-width: 7.625rem;
        margin: 0 3.125rem 0 0;
        transform: translateX(0);
        z-index: 0;
        height: ${props =>
            // here: remake
            Math.floor(
                props.backgroundHeight /
                    (props.animationItemHeight - ITEM_ROTATED_HEIGHT_TABLET) +
                    1
            ) *
                68 +
            42}px;

        div {
            & svg {
                width: auto;
                height: auto;
            }
        }

        & path {
            display: none;

            &:nth-child(-n
                    + ${props =>
                        Math.floor(
                            props.backgroundHeight /
                                (props.animationItemHeight -
                                    ITEM_ROTATED_HEIGHT_TABLET) +
                                1
                        )}) {
                display: block;
            }
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-right: 1.5rem;
        min-width: 3.25rem;
        overflow: hidden;
        max-height: 58.375rem;

        height: ${props =>
            Math.floor(
                props.backgroundHeight /
                    (props.animationItemHeight - ITEM_ROTATED_HEIGHT_MOBILE) +
                    1
            ) *
                27 +
            15}px;

        & path {
            display: none;

            &:nth-child(-n
                    + ${props =>
                        Math.floor(
                            props.backgroundHeight /
                                (props.animationItemHeight -
                                    ITEM_ROTATED_HEIGHT_MOBILE) +
                                1
                        )}) {
                display: block;
            }
            &:nth-child(n
                    + ${props =>
                        Math.floor(
                            props.backgroundHeight /
                                (props.animationItemHeight -
                                    ITEM_ROTATED_HEIGHT_MOBILE) +
                                2
                        )}) {
                display: none;
            }
        }
    }
`;

export const StepsWrapper = styled.div`
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        display: flex;
    }
`;
